<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.arrears')">
                <el-input
                  type="text"
                  v-model="formInline.startMoney"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width: 150px"
                  placeholder="请输入"
                ></el-input
                >至
                <el-input
                  type="text"
                  v-model="formInline.endMoney"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width: 150px"
                  placeholder="请输入"
                ></el-input
                >元
              </el-form-item>
              <el-form-item :label="$t('searchModule.Recovery_of_actual_payment_rate')">
                <el-input
                  type="text"
                  v-model="formInline.startActualArrearsRate"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width: 150px"
                  placeholder="请输入"
                ></el-input
                >至
                <el-input
                  type="text"
                  v-model="formInline.endActualArrearsRate"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width: 150px"
                  placeholder="请输入"
                ></el-input
                >%
              </el-form-item>
              <el-form-item :label="$t('searchModule.Recovery_type')" prop="tradeNo">
                <el-select v-model="formInline.arrearsType" placeholder="请选择">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="短信" :value="1"></el-option>
                  <el-option label="电话" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Task_status')" prop="tradeNo">
                <el-select v-model="formInline.status" placeholder="请选择">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="生效中" :value="6"></el-option>
                  <el-option label="已完结" :value="7"></el-option>
                  <el-option label="已超时" :value="5"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">清空</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <!-- 配合广州演示临时去掉操作列 -->
          <!-- <el-table-column label="操作" align="center">
          <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="handleDelect($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0">操作<i class="el-icon-arrow-down" /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.status == 6" command="1">手动关闭 </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
        </el-table-column> -->
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setIndex, dateFormat, exportExcelNew } from "@/common/js/public.js";
import { compareSize } from "@/common/js/utils.js";
const now = new Date();
const start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
start.setHours(0);
start.setMinutes(0);
start.setSeconds(0);
now.setHours(23);
now.setMinutes(59);
now.setSeconds(59);
export default {
  name: "staggeredOrder",
  data() {
    return {
      plateNumber: "",
      rowData: {},
      temParkData: {},
      temParkData1: {},
      infoSize: 4,
      exportData: {},
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      modelvalue1: "",
      rule: {},
      options: [
        {
          label: "短信",
          value: 0,
        },
        {
          label: "电话",
          value: 1,
        },
      ],
      tableCols: [
        {
          prop: "arrearsCode",
          label: "追缴编号",
          width: "",
        },
        {
          prop: "operatorName",
          label: "追缴负责人",
          width: "",
        },
        {
          prop: "arrearsType",
          label: "追缴类型",
          width: "",
          formatter: (row) => {
            return row.arrearsType && row.arrearsType == 1 ? "短信" : "电话";
          },
        },
        {
          prop: "arrearsTime",
          label: "追缴时间",
        },
        {
          prop: "status",
          label: "任务状态",
          formatter: (row) => {
            if (row.status == "5") {
              return "已超时";
            } else if (row.status == "6") {
              return "生效中";
            } else if (row.status == "7") {
              return "已完结";
            } else {
              return row.status;
            }
          },
        },
        {
          prop: "money",
          label: "追缴金额",
          width: "",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2) + "元";
            } else {
              return "0";
            }
          },
        },
        {
          prop: "carNum",
          label: "追缴车辆总数",
          width: "",
        },
        {
          prop: "reachCarCount",
          label: "可触达车辆数",
          width: "",
        },
        {
          prop: "reachMoney",
          label: "可触达金额",
          width: "",
          formatter: (row, column) => {
            if (row.reachMoney) {
              return Number(row.reachMoney / 100).toFixed(2) + "元";
            } else {
              return "0";
            }
          },
        },
        {
          prop: "planReachRate",
          label: "预估触达率",
          width: "",
          formatter: (row) => {
            return row.planReachRate + "%";
          },
        },
        {
          prop: "actualReachRate",
          label: "实际触达率",
          width: "",
          formatter: (row) => {
            return row.actualReachRate + "%";
          },
        },
        {
          prop: "actualReachCarNum",
          label: "实际追缴车辆",
          width: "",
        },
        {
          prop: "actualReachMoney",
          label: "实际追缴金额",
          width: "",
          formatter: (row, column) => {
            if (row.actualReachMoney) {
              return Number(row.actualReachMoney / 100).toFixed(2) + "元";
            } else {
              return "0";
            }
          },
        },
        {
          prop: "actualArrearsRate",
          label: "追缴实缴率",
          width: "",
          formatter: (row) => {
            return row.actualArrearsRate + "%";
          },
        },
      ],

      tableData: [],
      page: 1,
      size: 15,
      pickerOptions: {
        disabledDate: (val) => {
          // const now = new Date().getTime();
          return val.getTime() > now;
        },
      },
      formInline: {
        startMoney: "",
        endMoney: "",
        startActualArrearsRate: "",
        endActualArrearsRate: "",
        status: "",
        arrearsType: "",
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    searchData() {
      const { startMoney, endMoney, startActualArrearsRate, endActualArrearsRate } =
        this.formInline;
      if (!compareSize(startMoney, endMoney, "欠费金额")) return;
      if (!compareSize(startActualArrearsRate, endActualArrearsRate, "追缴实缴率")) return;
      this.$axios
        .get("/acb/2.0/arrears/queryArrearsReport", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            startMoney: this.formInline.startMoney ? this.formInline.startMoney * 100 : "",
            endMoney: this.formInline.endMoney ? this.formInline.endMoney * 100 : "",
            startActualArrearsRate: this.formInline.startActualArrearsRate
              ? this.formInline.startActualArrearsRate
              : "",
            endActualArrearsRate: this.formInline.endActualArrearsRate
              ? this.formInline.endActualArrearsRate
              : "",
            status: this.formInline.status,
            arrearsType: this.formInline.arrearsType,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
      if (!this.formInline.parkId) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
    },
    handleDelect(command, row) {
      this.$confirm("将关闭完结本追缴任务，是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/recoverTask/handleClose", {
              data: {
                taskId: row.taskId,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  message: res.desc,
                  type: "success",
                });
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {});
    },
    // 搜索
    searParkRecordList() {
      // console.log(this.formInline);
      this.loading = true;
      if (this.formInline.parkId == "") {
        this.formInline.parkName = "";
      }
      this.searchData();
    },
    resetForm() {
      this.formInline.startMoney = "";
      this.formInline.endMoney = "";
      this.formInline.startActualArrearsRate = "";
      this.formInline.endActualArrearsRate = "";
      this.formInline.status = "";
      this.formInline.arrearsType = "";
    },
  },
  components: {},
  created() {},
  mounted() {
    // console.log("当前权限为", this.$route.meta.authority.button);
    this.pageNum = 1;
    this.pageSize = 15;
    this.searchData();
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
